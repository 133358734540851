<template>
    <div>
        <br>
        <PostContainer :headless="true">
            <Banner src="/assets/img/camera.jpeg" :clipped="false" height="200px" />
            <div>
                <v-alert v-if="error" type="error" dismissible>
                    <p class="white--text">{{ error }}</p>
                </v-alert>
                <v-alert v-else-if="message" dismissible type="success">
                    <p class="white--text">{{ message }}</p>
                </v-alert>
            </div>

            <v-form  style="width: 100%;" ref="form">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined dense
                            type="password"
                            v-model="authForm.password"
                            :rules="rules.password"
                            label="Password Nova"
                            data-vv-name="password"
                            required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            outlined dense
                            type="password"
                            v-model="authForm.passwordConfirm"
                            :rules="[v => authForm.password === v || 'Password deve combinar.']"
                            label="Confirmar Password"
                            data-vv-name="password-confirm"
                            required></v-text-field>
                    </v-col>
                </v-row>
                <v-col cols="12">
                    <v-btn
                        @click="submit"
                        type="button"
                        color="primary"
                        :disabled="loadingReset"
                        :loading="loadingReset">
                        <span class="black--text">Recuperar</span>
                    </v-btn>
                   
                </v-col>
            </v-form>
        </PostContainer>
        <br>
    </div>
</template>
<script>

import Banner from '../../components/Banner';
import PostContainer from '../../components/PostContainer';

import { mapActions, mapState } from 'vuex';

const RULES = {
    required: (field) => v => !!v || `${field} é obrigatório.`,
    max: (field, len) => v => !v || v.length <= len || `${field} pode ter no máximo ${len} caracteres.`,
    min: (field, len) => v => (v && v.length >= len) || `${field} pode ter no mínimo ${len} caracteres.`,
    username: () => v => /^\w+(-?\w+)*$/.test(v) || `Apenas A-Z,a-z,0-9 e hífen como separador são permitidos.`,
};

export default {
    /*$_veeValidate: {
        validator: 'new',
    },*/
    data: () => ({
        authForm: {
            password: null,
            passwordConfirm: null,
        },
        rules: {
            password: [RULES.required('Password'), RULES.min('Password', 6), RULES.max('Password', 16)],
            passwordConfirm: null,
        },
        error: null,
        message: null,
    }),
    methods: {
        ...mapActions('auth', ['confirmResetProfilePassword']),
        submit() {
            this.error = null;
            this.message = null;

            if (this.$user.isAuthenticated) {
                this.error = 'Já se encontra autenticado.'
                return;
            }

            if (!this.$refs.form.validate()) return;
            const token = this.$route.params.token;
            const uid =  this.$route.params.uid;
            this.confirmResetProfilePassword({
                password: this.authForm.password, token, uid
            }).then((state) => {
                if (state.success) {
                    this.message =  'Operação realizado com sucesso.';
                    this.$refs.form.reset();
                    this.valid = true;
                } else {
                    this.error = state.error;
                }
            }).catch(() => {
                this.error = 'Problema ao registar utilizador';
            });
        },
    },
    computed: mapState('auth', ['loadingReset']),
    components: {
        Banner,
        PostContainer,
    }
}
</script>